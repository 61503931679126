const config = {
    oidc: {
      clientId: '0oa1wjsnbie317BZP0h8',
      issuer: 'https://atriahealth.okta.com/',
      //redirectUri: `http://localhost:3000/login/callback`,
      redirectUri: `https://ai.atria.org/login/callback`,
      scopes: ['openid', 'profile', 'email'],
      pkce: true,
    },
  };

  
  
  export default config;
  
